
import {Component, Mixins, Prop, Ref} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {BankaHacziTalep} from "@/interface/IUyapTalep";
import HacizTalepBankaPicker from "@/components/pickers/uyap/HacizTalepBankaPicker.vue";
import HacizTalepHesapPicker from "@/components/pickers/uyap/HacizTalepHesapPicker.vue";
import HacizTalepEvrakAdiRadio from "@/components/radio/uyap/HacizTalepEvrakAdiRadio.vue";
import HacizTalepBankaHaricAdiRadio from "@/components/radio/uyap/HacizTalepBankaHaricAdiRadio.vue";
import Dates from "@/components/inputs/Dates.vue";

interface BankaHacziGenelTalep extends BankaHacziTalep {
  muafBankaList: Array<string>
}

@Component({
  components: {
    Dates,
    HacizTalepBankaHaricAdiRadio, HacizTalepEvrakAdiRadio, HacizTalepHesapPicker, HacizTalepBankaPicker
  }
})
export default class BankaHacziTalepEvrakForm extends Mixins(ObjectInputMixin) {
  @Prop() kisiMi !: boolean | undefined;
  @Ref('bankapicker') bankaPicker !: HacizTalepBankaPicker;
  @Ref('hesappicker') hesapPicker !: HacizTalepHesapPicker;
  @Prop() mevcutBankalar !: Array<{ bankaAdi: string, selected: boolean }>;
  @Prop() showMuafBankalar !: boolean | string;

  localValue!: BankaHacziTalep | BankaHacziGenelTalep;

  get isShowMuafBankalar() {
    return this.showMuafBankalar || this.showMuafBankalar === "";
  }

  tumunuSecBankaHesap() {
    this.bankaPicker.tumunuSec();
    this.hesapPicker.tumunuSec();
  }

  onEvrakAdiChange(e: string) {
    this.localValue.evrakAdiSTR = e;
    if (!(this.localValue.evrakAdi == 'IHB_2' || this.localValue.evrakAdi == 'IHB_3')) {
      this.localValue.tebligTarihi = '';
    }
    this.input();
  }
}
