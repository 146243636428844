
import {Vue, Component, Prop, Mixins} from 'vue-property-decorator';
import NewFormWrapper from "@/components/NewFormWrapper.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import MahrumiyetInfo from "@/components/infos/eicrapro/MahrumiyetInfo.vue";
import BankaHacziTalepEvrakForm from "@/components/forms/uyap-evrak/haciz-evrak/BankaHacziTalepEvrakForm.vue";
import IhbarnameTalepEvrakForm from "@/components/forms/uyap-evrak/haciz-evrak/IhbarnameTalepEvrakForm.vue";
import TasinirHacizTalimatiTalepEvrakForm
  from "@/components/forms/uyap-evrak/haciz-evrak/TasinirHacizTalimatiTalepEvrakForm.vue";
import TasinirHacizTalepEvrakForm from "@/components/forms/uyap-evrak/haciz-evrak/TasinirHacizTalepEvrakForm.vue";
import MaasHacziTalepEvrakForm from "@/components/forms/uyap-evrak/haciz-evrak/MaasHacziTalepEvrakForm.vue";
import {UyapHacizEvrakTuru} from "@/plugins/uyap-plugin/enum/uyap/evrak/UyapHacizEvrakTuru";
import {AvukatTalepListCevapRow} from "@/plugins/uyap-plugin/uyap/AvukatTalepList";
import {MDB} from "@/plugins/uyap-plugin/MDB";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {IMahrumiyet} from "@/plugins/uyap-plugin/uyap/DosyaBorcluEgmMahrumiyet";

@Component({
  computed: {
    HacizEvrakTuru() {
      return UyapHacizEvrakTuru
    },
    MDB() {
      return MDB
    }
  },
  components: {
    MaasHacziTalepEvrakForm,
    TasinirHacizTalepEvrakForm,
    TasinirHacizTalimatiTalepEvrakForm,
    IhbarnameTalepEvrakForm,
    BankaHacziTalepEvrakForm,
    MahrumiyetInfo,
    FormDialog,
    NewFormWrapper}
})
export default class TopluHacizTalepDuzenleForm extends Mixins(ObjectInputMixin) {
  @Prop() talepler!: Array<AvukatTalepListCevapRow>;
  @Prop() dosyaId!: number|string;
  // takip.task.response.dosya.dosyaId
  showTalep(talepKod: UyapHacizEvrakTuru): boolean {
    return !!this.talepler.find((talep: AvukatTalepListCevapRow) => (talep.talepKodu == talepKod));
  }

  tumunuSec(items: Array<any>) {
    if (items) {
      let selected = !(items.filter(item => item.selected).length == items.length)
      items.forEach(item => {
        item.selected = selected;
      });
      this.input();
    }
  }

  getTakyidatSirasi(items: IMahrumiyet[]) {
    return Math.max(...items.map(o => o.takyidatSayisi));
  }
}
