
import {Component, Mixins, Prop} from "vue-property-decorator";
import {mask} from "vue-the-mask";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {KurumSorgulamaTalep} from "@/plugins/uyap-plugin/uyap/KurumSorgulama";

@Component({
  directives: {
    mask
  },
})
export default class DetsisNumarasi extends Mixins(SimpleInputMixin) {
  @Prop() required!: any;
  @Prop() uyapSorgula !: string | boolean;
  @Prop() readonly !: string | boolean;

  mask = "################";
  rules = [
    (value: any) => {
      return (!this.isRequired || !!value) || "Lütfen bu alanı doldurun."
    },
    (value: any) => {
      return (!value || (value && value.length == 8)) || "8 karakter olmalıdır.";
    },
  ];

  get isRequired() {
    return this.required || this.required === "";
  }

  get isUyapSorgulaButton() {
    return this.uyapSorgula || this.uyapSorgula === "";
  }

  get isReadOnly() {
    return this.readonly || this.readonly === "";
  }

  async sorgula() {
    let talep: KurumSorgulamaTalep = {detsisNo: this.localValue,mersisNo:'',kurumAdi:'',vergiNo:''};
    let cevap = await this.$uyap.KurumSorgulama().run(talep);
    this.$emit('uyap', cevap);
  }

  validate() {
    this.$emit("value", this.localValue);
  }
}
