
import {Component, Prop, Vue} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import {IMahrumiyet} from "@/plugins/uyap-plugin/uyap/DosyaBorcluEgmMahrumiyet";

@Component({
  components: {FormWrapper}
})
export default class MahrumiyetInfo extends Vue {
  @Prop() items!: Array<IMahrumiyet>;
  @Prop() message!: string;

  headers = [
    {text: 'Takyidat Sırası', value: 'takyidatSirasi'},
    {text: 'Ekleyen Birim', value: 'ekleyenBirim'},
    {text: 'Ekleme Tarihi', value: 'eklemeTarihi'},
    {text: 'Şerh Türü', value: 'takyidatAdi'},
    {text: 'Kurum Adı', value: 'kurumAd'}
  ];
}
