
import {Component, Mixins, Prop} from "vue-property-decorator";
import {BankaHacziParamsEvrakAdiRow} from "@/plugins/uyap-plugin/uyap/BankaHacziParams";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {}
})

export default class HacizTalepEvrakAdiRadio extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;
  @Prop() showUyapIcon!: string | boolean;
  @Prop() tooltip!: string;

  items: Array<BankaHacziParamsEvrakAdiRow> = [];

  get isShowUyapIcon() {
    return this.showUyapIcon || this.showUyapIcon === "";
  }

  onEvrakAdiChange(v: BankaHacziParamsEvrakAdiRow) {
    this.$emit('evrakAdiSTR', this.items.find(item => item.value == this.localValue)?.name);
    this.input();
  }

  created() {
    this.load();
  }

  async load() {
    this.items = (await this.$uyap.BankaHacziParams().run({})).evrakAdlari;
  }
}
