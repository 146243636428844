
import {Component, Mixins, Prop} from "vue-property-decorator";
import {BankaHacziParamsHaricRow} from "@/plugins/uyap-plugin/uyap/BankaHacziParams";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {}
})

export default class HacizTalepBankaHaricAdiRadio extends Mixins(SimpleInputMixin) {
  @Prop() borcluKisiMi!: boolean | undefined;
  @Prop() rules!: string;
  @Prop() showUyapIcon!: string | boolean;
  @Prop() tooltip!: string;

  items: Array<BankaHacziParamsHaricRow> = [];

  get isShowUyapIcon() {
    return this.showUyapIcon || this.showUyapIcon === "";
  }

  onHaricAdiChange(v: BankaHacziParamsHaricRow) {
    this.$emit('haricAdiSTR', this.items.find(item => item.value == this.localValue)?.name);
    this.input();
  }

  created() {
    this.load();
    //TODO: Kişi ve değer 'MAAS' ise veya Kurum ve 'BELEDİYE' ise seçili olmalı
  }

  async load() {
    this.items = (await this.$uyap.BankaHacziParams().run({})).haric;
  }
}
