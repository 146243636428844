

import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {TakibiKesinlesenBorcluListesiCevapRow} from "@/plugins/uyap-plugin/uyap/TakibiKesinlesenBorcluListesi";

@Component({
  components: {}
})

export default class TarafListPicker extends Mixins(SimpleInputMixin) {
  @Prop({required: true}) dosyaId!: number;
  @Prop() rules!: string;
  @Prop() label!: string;
  @Prop() showUyapIcon!: string | boolean;
  @Prop() tooltip!: string;

  items: Array<TakibiKesinlesenBorcluListesiCevapRow> = [];

  get isShowUyapIcon() {
    return this.showUyapIcon || this.showUyapIcon === "";
  }

  created() {
    this.load();
  }

  async load() {
    this.items = (await this.$uyap.TakibiKesinlesenBorcluListesi().run({dosyaId: this.dosyaId})).items;
  }
}
