
import {Component, Mixins, Ref} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import TarafSifatiPicker from "@/components/pickers/TarafSifatiPicker.vue";
import TcKimlikNumarasi from "@/components/inputs/TcKimlikNumarasi.vue";
import {KisiSorgulamaCevap} from "@/plugins/uyap-plugin/uyap/KisiSorgulama";
import TakipRolleri, {TakipRol} from "@/plugins/uyap-plugin/takip_ac/parser/TakipRolleri";
import PostaKodu from "@/components/inputs/PostaKodu.vue";
import AdresTuruPicker from "@/components/pickers/AdresTuruPicker.vue";
import {MDB} from "@/plugins/uyap-plugin/MDB";
import MersisNumarasi from "@/components/inputs/MersisNumarasi.vue";
import VergiNumarasi from "@/components/inputs/VergiNumarasi.vue";
import DetsisNumarasi from "@/components/inputs/DetsisNumarasi.vue";
import {KurumSorgulamaCevap} from "@/plugins/uyap-plugin/uyap/KurumSorgulama";
import {GetKurumMersisAdresiCevap} from "@/plugins/uyap-plugin/uyap/GetKurumMersisAdresi";
import {VForm} from "@/types";

@Component({
  components: {
    DetsisNumarasi,
    VergiNumarasi,
    MersisNumarasi, AdresTuruPicker, PostaKodu, TcKimlikNumarasi, TarafSifatiPicker, FormWrapper
  }
})
export default class UcuncuTarafForm extends Mixins(SimpleInputMixin) {
  @Ref('form') readonly form!: VForm;
  valid = false;
  selectedAdres: Array<any> = [];
  adresler: Array<any> = [];
  adresErr = false;
  headers = [
    {text: 'Adres Türü', value: 'adresTuruAciklama'},
    {text: 'İl/İlçe', value: 'ilIlce'},
    {text: 'Adres', value: 'adres'},
    {text: 'Posta Kodu', value: 'postaKodu'}
  ]
  roles: Array<TakipRol> = [];
  tempAdres: Adres = new Adres();
  mdb = new MDB();
  mernisAdresiVarMi: boolean = false;
  kisiIslemDegerleri = {
    ad: null as string|null,
    soyad: null as string|null
  }
  kurumIslemDegerleri = {
    mersisNo: null as string|null,
    vergiNo: null as number|null,
    detsisNo:null as string|null,
    kurumAd: null as string|null
  }
  illerIlceler: any = [];

  async onKisiBilgileriChange(uyapData: KisiSorgulamaCevap) {
    this.localValue.temelBilgileri = uyapData;
    this.kisiIslemDegerleri.ad = uyapData.adi;
    this.kisiIslemDegerleri.soyad = uyapData.soyadi;
    this.mernisAdresiVarMi = await this.$uyap.KisiMernisAdresiVarmi().run({tcKimlikNo: uyapData.tcKimlikNo});
    this.localValue.mernisAdresiKullan = this.mernisAdresiVarMi;
    this.input();
    this.adresler = await this.$uyap.GetAdresList().run({
      kisiKurumId: uyapData.kisiKurumID,
      tarafTur: this.localValue.tarafSifati
    });
  }

  async onKurumBilgileriChange(uyapData: Array<KurumSorgulamaCevap>) {
    // TODO: Array geliyor
    this.localValue.temelBilgileri = uyapData[0];
    this.kurumIslemDegerleri.kurumAd = uyapData[0].kurumAdi;
    this.kurumIslemDegerleri.detsisNo = uyapData[0]?.detsisNo;
    this.kurumIslemDegerleri.mersisNo = uyapData[0].mersisNo;
    this.kurumIslemDegerleri.vergiNo = uyapData[0].vergiNo;
    let mersisData: GetKurumMersisAdresiCevap = await this.$uyap.GetKurumMersisAdresi().run({
      mersisNo: uyapData[0].mersisNo,
      vergiNo: uyapData[0].vergiNo
    });
    this.mernisAdresiVarMi = !!mersisData;
    this.localValue.mernisAdresiKullan = this.mernisAdresiVarMi;
    this.input();
    this.adresler = await this.$uyap.GetAdresList().run({
      kisiKurumId: uyapData[0].kisiKurumID,
      tarafTur: this.localValue.tarafSifati
    });
  }

  onTarafSifatiChange() {
    delete this.localValue.tarafSifati.sanikStatusu;
    delete this.localValue.tarafSifati.davaliDavaciGrubu;
    this.input();
  }

  onAdresTuruChange(adresTuruObj: any) {
    if (adresTuruObj) {
      this.tempAdres.adresTuruAciklama = adresTuruObj.isim;
      this.tempAdres.adresTuru = adresTuruObj.uyap_kod;
    }
  }

  onIlChange(ilObj: any) {
    if (ilObj) {
      this.tempAdres.ilKodu = ilObj.il;
      this.tempAdres.ilAdi = ilObj.ad;
    }
  }

  onIlceChange(ilceObj: any) {
    if (ilceObj) {
      this.tempAdres.ilceKodu = ilceObj.ilcekod;
      this.tempAdres.ilceAdi = ilceObj.ilcead;
    }
  }

  async mounted() {
    this.illerIlceler = await this.$uyap.IllerIlceler().run({});
    const takipRolleri = new TakipRolleri();
    this.roles = takipRolleri.ucuncuTarafRoller;
  }

  addAdres() {
    if (this.form.validate()) {
      this.tempAdres.adresID = 'T' + (this.adresler.length + 1);
      this.adresler.push(this.tempAdres);
      this.tempAdres = new Adres();
    }
  }

  save() {
    if (!this.localValue.mernisAdresiKullan && this.selectedAdres.length == 0)
      this.adresErr = true;
    else {
      this.adresErr = false;
      this.localValue.adresBilgisi = {
        ilKodu: this.selectedAdres[0].ilKodu,
        ilAdi: this.selectedAdres[0].ilAdi,
        ilceKodu: this.selectedAdres[0].ilceKodu,
        ilceAdi: this.selectedAdres[0].ilceAdi,
        adres: this.selectedAdres[0].adres,
        adresTuru: this.selectedAdres[0].adresTuru,
        adresTuruAciklama: this.selectedAdres[0].adresTuruAciklama,
        postaKodu: this.selectedAdres[0].postaKodu
      }
      this.localValue.sorguTuru = 0
    }
    console.log(this.localValue)
  }
}

class Adres {
  adresID: string = "";
  ilKodu: string = "";
  ilAdi: string = "";
  ilObj: object = {};
  ilceKodu: string = "";
  ilceAdi: string = "";
  ilceObj: object = {};
  adres: string = "";
  adresTuru: string = "";
  adresTuruAciklama: string = "";
  adresTuruObj: object = {};
  postaKodu: string = "";
}
