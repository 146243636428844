
import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import {BankaHacziParamsHesapRow} from "@/plugins/uyap-plugin/uyap/BankaHacziParams";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {}
})

export default class HacizTalepHesapPicker extends Mixins(SimpleInputMixin) {
  @Prop() showUyapIcon!: string | boolean;
  @Prop() tooltip!: string;
  @Prop() maxDisplay!: number;

  items: Array<BankaHacziParamsHesapRow> = [];

  get isShowUyapIcon() {
    return this.showUyapIcon || this.showUyapIcon === "";
  }

  created() {
    this.load();
  }

  tumunuSec() {
    this.$nextTick(() => {
      if (this.localValue?.length == this.items.length) {
        this.localValue = []
      } else {
        this.localValue = this.items.map(x=>x.tktId);
      }
      this.input();
    })
  }

  get icon() {
    if (this.localValue?.length == this.items.length)
      return 'mdi-close-box';
    if (this.localValue?.length > 0)
      return 'mdi-minus-box';
    return 'mdi-checkbox-blank-outline';
  }

  async load() {
    this.items = (await this.$uyap.BankaHacziParams().run({})).hesaplar.sort((a, b) => (a.aciklama.localeCompare(b.aciklama)));
  }
}
