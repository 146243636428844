

import {Component, Mixins, Prop} from "vue-property-decorator";
import {GetDosyaTarafListesiWithTarafRolCevapRow} from "@/plugins/uyap-plugin/uyap/GetDosyaTarafListesiWithTarafRol";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {}
})

export default class UcuncuSahisPicker extends Mixins(SimpleInputMixin) {
  @Prop({required: true}) dosyaId!: number;
  @Prop() rules!: string;
  @Prop() label!: string;
  @Prop() showUyapIcon!: string | boolean;
  @Prop() tooltip!: string;

  items: Array<GetDosyaTarafListesiWithTarafRolCevapRow> = [];

  get isShowUyapIcon() {
    return this.showUyapIcon || this.showUyapIcon === "";
  }

  onAlacakliChange(v: GetDosyaTarafListesiWithTarafRolCevapRow) {
    this.$emit('temlikAlacakliStr', (this.items.find(item => item.guncelKisiKurumId == this.localValue))?.tarafAdi);
    this.input();
  }

  created() {
    this.load();
  }

  async load() {
    this.items = (await this.$uyap.GetDosyaTarafListesiWithTarafRol().run({
      dosyaId: this.dosyaId,
      islemTuru: 26
    })).items;
  }
}
