

import {Component, Mixins, Prop} from "vue-property-decorator";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {DatabasePicker}
})
export default class TarafSifatiPicker extends Mixins(SimpleInputMixin) {
  @Prop() returnId: any;
  @Prop() rules!: any;
  @Prop() color!: any;

}
