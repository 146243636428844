import {DosyaBorclu} from "@/plugins/uyap-plugin/uyap/DosyaBorclu";
import {AvukatTalepListCevapRow} from "@/plugins/uyap-plugin/uyap/AvukatTalepList";
import {UyapTebligatEvrakTuru} from "@/plugins/uyap-plugin/enum/uyap/evrak/UyapTebligatEvrakTuru";
import {TarafRol} from "@/plugins/uyap-plugin/enum/uyap/TarafRol";
import app from "@/main";

export class VarsayilanTebligat {
    icraOdemeEmrininTebligeCikartilmasi = {
        tebligatTuru: 0,
        tebligatTuruAciklama: "",
        adresTuru: 0,                   // mernis/mersis
        adresTuruAciklama: "",          // türe göre mernis/mersis
        adres: ""
    }

    kiymetTakdirininTebligeCikartilmasi = {
        tebligatTuru: 0,
        tebligatTuruAciklama: "",
        adresTuru: 0,                   // mernis/mersis
        adresTuruAciklama: "",          // türe göre mernis/mersis
        adres: "",
        kiymetTakdirTarihi: "",
        malTip: 0,
        malTipAciklama: "",
        aciklama: "",
    }

    taahuduKabulMuhtirasiTebligi = {
        tebligatTuru: 0,
        tebligatTuruAciklama: "",
        adresTuru: 0,                   // mernis/mersis
        adresTuruAciklama: "",          // türe göre mernis/mersis
        adres: "",
        taahhutTarihi: "",
        aciklama: ""
    }

    temlikBilgisininBildirilmesi = {
        tebligatTuru: 0,
        tebligatTuruAciklama: "",
        adresTuru: 0,                   // mernis/mersis
        adresTuruAciklama: "",          // türe göre mernis/mersis
        adres: "",
        kismiTemlikMi: false,
        kismiTemlikMiStr: "",
        temlikTipi: 0,
        temlikTutari: "",
        temlikTutarTuru: "",
        temlikTutarTuruStr: "",
        temlikAlacakli: 0,
        temlikAlacakliStr: "",
        temlikEdilen: "",
        temlikEdilenStr: ""
    }

    yenilemeEmrininTebligeCikartilmasi = {
        tebligatTuru: 0,
        tebligatTuruAciklama: "",
        adresTuru: 0,                   // mernis/mersis
        adresTuruAciklama: "",          // türe göre mernis/mersis
        adres: ""
    }

    yuzucDavetiyesininTebligeCikartilmasi = {
        tebligatTuru: 0,
        tebligatTuruAciklama: "",
        adresTuru: 0,                   // mernis/mersis
        adresTuruAciklama: "",          // türe göre mernis/mersis
        adres: "",
        hacizTarihi: "",
        malTip: 0,
        malTipAciklama: "",
        aciklama: ""
    }

    async setVarsayilanTalepler(borclu: DosyaBorclu, talepler: Array<AvukatTalepListCevapRow>, dosyaId: string): Promise<DosyaBorclu> {
        if (talepler.some(talep => talep.talepKodu == UyapTebligatEvrakTuru.icra_odeme_emrinin_teblige_cikartilmasi))
            this.setVarsayilanIcraOdemeEmrininTebligeCikartilmasi(borclu);
        if (talepler.some(talep => talep.talepKodu == UyapTebligatEvrakTuru.kiymet_takdirinin_teblige_cikartilmasi))
            this.setVarsayilanKiymetTakdirininTebligeCikartilmasi(borclu);
        if (talepler.some(talep => talep.talepKodu == UyapTebligatEvrakTuru.taahhudu_kabul_muhtirasi_tebligi))
            this.setVarsayilanTaahuduKabulMuhtirasiTebligi(borclu);
        if (talepler.some(talep => talep.talepKodu == UyapTebligatEvrakTuru.temlik_bilgisinin_bildirilmesi))
            await this.setVarsayilanTemlikBilgisininBildirilmesi(borclu, dosyaId);
        if (talepler.some(talep => talep.talepKodu == UyapTebligatEvrakTuru.yenileme_emrinin_teblige_cikartilmasi))
            this.setVarsayilanYenilemeEmrininTebligeCikartilmasi(borclu);
        if (talepler.some(talep => talep.talepKodu == UyapTebligatEvrakTuru.yuz_uc_davetiyesinin_teblige_cikartilmasi))
            this.setVarsayilanYuzucDavetiyesininTebligeCikartilmasi(borclu);
        return borclu;
    }


    setVarsayilanIcraOdemeEmrininTebligeCikartilmasi(borclu: DosyaBorclu) {
        let adresTuru = this.icraOdemeEmrininTebligeCikartilmasi.adresTuru;
        let adresTuruAciklama = this.icraOdemeEmrininTebligeCikartilmasi.adresTuruAciklama;
        if (adresTuru == 1) {
            if (borclu.isKurumBool) {
                adresTuru = 2;
                adresTuruAciklama = "Mersis Adresi"
            } else {
                adresTuru = 1;
                adresTuruAciklama = "Mernis Adresi";
            }
        }
        borclu.tebligat.icraOdemeEmrininTebligeCikartilmasiParams = {
            adres: this.icraOdemeEmrininTebligeCikartilmasi.adres,
            adresTuru: adresTuru,
            adresTuruAciklama: adresTuruAciklama,
            tebligatTuru: this.icraOdemeEmrininTebligeCikartilmasi.tebligatTuru,
            tebligatTuruAciklama: this.icraOdemeEmrininTebligeCikartilmasi.tebligatTuruAciklama
        }
    }

    setVarsayilanKiymetTakdirininTebligeCikartilmasi(borclu: DosyaBorclu) {
        let adresTuru = this.kiymetTakdirininTebligeCikartilmasi.adresTuru;
        let adresTuruAciklama = this.kiymetTakdirininTebligeCikartilmasi.adresTuruAciklama;
        if (adresTuru == 1) {
            if (borclu.isKurumBool) {
                adresTuru = 2;
                adresTuruAciklama = "Mersis Adresi"
            } else {
                adresTuru = 1;
                adresTuruAciklama = "Mernis Adresi";
            }
        }
        borclu.tebligat.kiymetTakdirininTebligeCikartilmasiParams = {
            aciklama: this.kiymetTakdirininTebligeCikartilmasi.aciklama,
            adres: this.kiymetTakdirininTebligeCikartilmasi.adres,
            adresTuru: adresTuru,
            adresTuruAciklama: adresTuruAciklama,
            kiymetTakdirTarihi: this.kiymetTakdirininTebligeCikartilmasi.kiymetTakdirTarihi,
            malTip: this.kiymetTakdirininTebligeCikartilmasi.malTip,
            malTipAciklama: this.kiymetTakdirininTebligeCikartilmasi.malTipAciklama,
            tebligatTuru: this.kiymetTakdirininTebligeCikartilmasi.tebligatTuru,
            tebligatTuruAciklama: this.kiymetTakdirininTebligeCikartilmasi.tebligatTuruAciklama
        }
    }

    setVarsayilanTaahuduKabulMuhtirasiTebligi(borclu: DosyaBorclu) {
        let adresTuru = this.taahuduKabulMuhtirasiTebligi.adresTuru;
        let adresTuruAciklama = this.taahuduKabulMuhtirasiTebligi.adresTuruAciklama;
        if (adresTuru == 1) {
            if (borclu.isKurumBool) {
                adresTuru = 2;
                adresTuruAciklama = "Mersis Adresi"
            } else {
                adresTuru = 1;
                adresTuruAciklama = "Mernis Adresi";
            }
        }
        borclu.tebligat.taahuduKabulMuhtirasiTebligiParams = {
            aciklama: this.taahuduKabulMuhtirasiTebligi.aciklama,
            adres: this.taahuduKabulMuhtirasiTebligi.adres,
            adresTuru: adresTuru,
            adresTuruAciklama: adresTuruAciklama,
            taahhutTarihi: this.taahuduKabulMuhtirasiTebligi.taahhutTarihi,
            tebligatTuru: this.taahuduKabulMuhtirasiTebligi.tebligatTuru,
            tebligatTuruAciklama: this.taahuduKabulMuhtirasiTebligi.tebligatTuruAciklama
        }
    }

    async setVarsayilanTemlikBilgisininBildirilmesi(borclu: DosyaBorclu, dosyaId: string) {
        let adresTuru = this.temlikBilgisininBildirilmesi.adresTuru;
        let adresTuruAciklama = this.temlikBilgisininBildirilmesi.adresTuruAciklama;
        if (adresTuru == 1) {
            if (borclu.isKurumBool) {
                adresTuru = 2;
                adresTuruAciklama = "Mersis Adresi"
            } else {
                adresTuru = 1;
                adresTuruAciklama = "Mernis Adresi";
            }
        }
        let alacaklilar = await app.$uyap.GetDosyaTarafListesiWithTarafRol().run({
            dosyaId: dosyaId,
            islemTuru: TarafRol.ALACAKLI
        });
        borclu.tebligat.temlikBilgisininBildirilmesiParams = {
            adres: this.temlikBilgisininBildirilmesi.adres,
            adresTuru: adresTuru,
            adresTuruAciklama: adresTuruAciklama,
            kismiTemlikMi: this.temlikBilgisininBildirilmesi.kismiTemlikMi,
            kismiTemlikMiStr: this.temlikBilgisininBildirilmesi.kismiTemlikMiStr,
            tebligatTuru: this.temlikBilgisininBildirilmesi.tebligatTuru,
            tebligatTuruAciklama: this.temlikBilgisininBildirilmesi.tebligatTuruAciklama,
            temlikAlacakliAdi: alacaklilar[0].tarafAdi,
            temlikAlacakliKisiKurumId: alacaklilar[0].guncelKisiKurumId,
            temlikEdilen: this.temlikBilgisininBildirilmesi.temlikEdilen,
            temlikTipi: this.temlikBilgisininBildirilmesi.temlikTipi,
            temlikTutarTuru: this.temlikBilgisininBildirilmesi.temlikTutarTuru,
            temlikTutari: Number(this.temlikBilgisininBildirilmesi.temlikTutari)
        }
    }

    setVarsayilanYenilemeEmrininTebligeCikartilmasi(borclu: DosyaBorclu) {
        let adresTuru = this.yenilemeEmrininTebligeCikartilmasi.adresTuru;
        let adresTuruAciklama = this.yenilemeEmrininTebligeCikartilmasi.adresTuruAciklama;
        if (adresTuru == 1) {
            if (borclu.isKurumBool) {
                adresTuru = 2;
                adresTuruAciklama = "Mersis Adresi"
            } else {
                adresTuru = 1;
                adresTuruAciklama = "Mernis Adresi";
            }
        }
        borclu.tebligat.yenilemeEmrininTebligeCikartilmasiParams = {
            adres: this.yenilemeEmrininTebligeCikartilmasi.adres,
            adresTuru: adresTuru,
            adresTuruAciklama: adresTuruAciklama,
            tebligatTuru: this.yenilemeEmrininTebligeCikartilmasi.tebligatTuru,
            tebligatTuruAciklama: this.yenilemeEmrininTebligeCikartilmasi.tebligatTuruAciklama
        }
    }

    setVarsayilanYuzucDavetiyesininTebligeCikartilmasi(borclu: DosyaBorclu) {
        let adresTuru = this.yuzucDavetiyesininTebligeCikartilmasi.adresTuru;
        let adresTuruAciklama = this.yuzucDavetiyesininTebligeCikartilmasi.adresTuruAciklama;
        if (adresTuru == 1) {
            if (borclu.isKurumBool) {
                adresTuru = 2;
                adresTuruAciklama = "Mersis Adresi"
            } else {
                adresTuru = 1;
                adresTuruAciklama = "Mernis Adresi";
            }
        }
        borclu.tebligat.yuzucDavetiyesininTebligeCikartilmasiParams = {
            aciklama: this.yuzucDavetiyesininTebligeCikartilmasi.aciklama,
            adres: this.yuzucDavetiyesininTebligeCikartilmasi.adres,
            adresTuru: adresTuru,
            adresTuruAciklama: adresTuruAciklama,
            hacizTarihi: this.yuzucDavetiyesininTebligeCikartilmasi.hacizTarihi,
            malTip: this.yuzucDavetiyesininTebligeCikartilmasi.malTip,
            malTipAciklama: this.yuzucDavetiyesininTebligeCikartilmasi.malTipAciklama,
            tebligatTuru: this.yuzucDavetiyesininTebligeCikartilmasi.tebligatTuru,
            tebligatTuruAciklama: this.yuzucDavetiyesininTebligeCikartilmasi.tebligatTuruAciklama
        }
    }
}