
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {MaasHacziTalep} from "@/interface/IUyapTalep";
import HacizTalepMaasHacziCalismaDurumuPicker
  from "@/components/pickers/uyap/HacizTalepMaasHacziCalismaDurumuPicker.vue";
import DosyaAraciKisiKurumTarafListesiPicker from "@/components/pickers/uyap/DosyaAraciKisiKurumTarafListesiPicker.vue";
import Tutar from "@/components/inputs/Tutar.vue";

@Component({
  components: {Tutar, DosyaAraciKisiKurumTarafListesiPicker, HacizTalepMaasHacziCalismaDurumuPicker}
})
export default class MaasHacziTalepEvrakForm extends Mixins(ObjectInputMixin) {
  @Prop() dosyaId!: number;
  @Prop() varsayilan!: boolean|string;
  localValue!: MaasHacziTalep;

  get isVarsayilan(){
    return this.varsayilan || this.varsayilan === "";
  }

  onNafakaChange() {
    if (!this.localValue.nafaka)
      this.localValue.nafakaTutari = "";
    this.input();
  }
}
