
import {Component, Mixins, Prop} from "vue-property-decorator";
import {mask} from "vue-the-mask";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {KurumSorgulamaTalep} from "@/plugins/uyap-plugin/uyap/KurumSorgulama";

@Component({
  directives: {
    mask
  },
})
export default class MersisNumarasi extends Mixins(SimpleInputMixin) {
  @Prop({default: false}) required !: any;
  @Prop({default:'Mersis No'}) label!: string;
  @Prop() uyapSorgula !: string | boolean;
  @Prop() readonly !: string | boolean;

  localValue = this.value ? this.value.toString() : null;

  mask = "################";
  rules = [
    (value: any) => {
      return (!this.isRequired || !!value) || "Lütfen bu alanı doldurun."
    },
    (value: any) => {
      return (!value || (value && value.length == 16)) || "16 karakter olmalıdır.";
    },
  ];

  get isRequired() {
    return this.required || this.required === "";
  }

  get isUyapSorgulaButton() {
    return this.uyapSorgula || this.uyapSorgula === "";
  }

  get isReadOnly() {
    return this.readonly || this.readonly === "";
  }

  validate() {
    this.$emit("value", this.localValue);
  }

  async sorgula() {
    let talep: KurumSorgulamaTalep= {mersisNo: this.localValue,kurumAdi:'',vergiNo:'',detsisNo:''};
    let cevap = await this.$uyap.KurumSorgulama().run(talep);
    this.$emit('uyap', cevap);
  }
}
