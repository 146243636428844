

import {Component, Vue} from "vue-property-decorator";
import {TaskTuru} from "@/plugins/uyap-plugin/enum/TaskTuru";
import TopluTebligatHazirlaFormWizard from "@/components/forms/form-wizard/eicrapro/TopluTebligatHazirlaFormWizard.vue";
import UyapBagliDegilView from "@/views/eicrapro/UyapBagliDegilView.vue";

@Component({
  components: {UyapBagliDegilView, TopluTebligatHazirlaFormWizard}
})
export default class TopluTebligatHazirlaView extends Vue {
  taskTuru = TaskTuru;
}
