
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {Ihbarname89_1Talep, Ihbarname89_2Talep, Ihbarname89_3Talep,} from "@/interface/IUyapTalep";
import UyapAdliyePicker from "@/components/pickers/uyap/UyapAdliyePicker.vue";
import UcuncuSahisPicker from "@/components/pickers/uyap/UcuncuSahisPicker.vue";
import Dates from "@/components/inputs/Dates.vue";
import AvukatTalepTebligatTuruPicker from "@/components/pickers/uyap/AvukatTalepTebligatTuruPicker.vue";
import AvukatTalepAdresTuruPicker from "@/components/pickers/uyap/AvukatTalepAdresTuruPicker.vue";
import TarafListPicker from "@/components/pickers/uyap/TarafListPicker.vue";
import {UyapHacizEvrakTuru} from "@/plugins/uyap-plugin/enum/uyap/evrak/UyapHacizEvrakTuru";
import DosyaAraciKisiKurumTarafListesiPicker from "@/components/pickers/uyap/DosyaAraciKisiKurumTarafListesiPicker.vue";

@Component({
  components: {
    DosyaAraciKisiKurumTarafListesiPicker,
    TarafListPicker,
    AvukatTalepAdresTuruPicker, AvukatTalepTebligatTuruPicker, Dates, UcuncuSahisPicker, UyapAdliyePicker
  }
})
export default class IhbarnameTalepEvrakForm extends Mixins(ObjectInputMixin) {
  @Prop() dosyaId!: number;
  @Prop() tckn!: string;
  @Prop() vergiNo!: string;
  @Prop() talepKodu!: number;
  @Prop() varsayilan!: string|boolean;
  localValue!: Ihbarname89_1Talep | Ihbarname89_2Talep | Ihbarname89_3Talep;
  uyapHacizEvrakTuru = UyapHacizEvrakTuru;
  kisiMi!:boolean ;

  sorguProgress:boolean=false;

  get isVarsayilan() {
    return this.varsayilan || this.varsayilan === "";
  }

  async adresSorgula() {
    this.sorguProgress = true;
    // mernis 1 mersis 2
    if (this.localValue.adresTuru == '1' && this.tckn) {
      let mernisRes = await this.$uyap.KisiMernisAdresiVarmi().run({tcKimlikNo: this.tckn});
      this.localValue.adres = mernisRes ? 'VAR' : 'YOK';
    } else if (this.localValue.adresTuru == '2' && this.vergiNo) {
      let mersisRes = await this.$uyap.GetKurumMersisAdresi().run({vergiNo: this.vergiNo,mersisNo:''});
      this.localValue.adres = !!mersisRes ? 'VAR' : 'YOK';
    }
    setTimeout(() => {
      this.sorguProgress=false
    }, 2000)
  }
}
